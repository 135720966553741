<template>
    <div class="login-container">
      <form @submit.prevent="login"> <!-- Updated to correct method name -->
        <div class="logo-container">
            <img :src="require('@/assets/logo2.svg')" alt="Logo" class="logo">
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="username" required> <!-- Bind data model -->
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required> <!-- Bind data model -->
        </div>
        <button type="submit" class="login-button">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'UserLogin',
    data() {
      return {
        username: '',
        password: '',
        message: ''
      };
    },
    methods: {
      async login() {
        try {
          const response = await axios.post('http://localhost:3333/user-auth', {
            username: this.username,
            password: this.password
          });
          if (response.data.accessToken) {
            this.message = "I'M IN";
            alert(this.message);  // Add alert for debugging
          }
        } catch (error) {
          this.message = 'Login failed: ' + error.response.data.message;
          alert(this.message);  // Add alert for debugging
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login-container {
    max-width: 300px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .logo-container img {
    display: block;
    margin: 0 auto 20px;
    width: 100px;  /* Adjust size as needed */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  input[type="email"], input[type="password"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
  }
  .login-button:hover {
    background-color: #0056b3;
  }
  </style>
  