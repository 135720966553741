import { createApp } from 'vue';
import App from './App.vue';
import { createVuetify } from 'vuetify/lib/framework'; // Import createVuetify
import '@mdi/font/css/materialdesignicons.css'; // Import Material Design Icons for Vuetify
import 'vuetify/styles'; // Import Vuetify CSS

// Use createVuetify to create the Vuetify instance
const vuetify = createVuetify({
    icons: {
        iconfont: 'mdi', // Specifies which icon font to use, 'mdi' for Material Design Icons
    }
});

// Create and configure the Vue app
const app = createApp(App);
app.use(vuetify); // Tell your Vue app to use Vuetify
app.mount('#app');
