<template>
  <div id="app">
    <UserLogin />
  </div>
</template>

<script>
import UserLogin from './components/Login.vue'

export default {
  name: 'App',
  components: {
    UserLogin
  }
}
</script>
